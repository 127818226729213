<template>
    <div>
        <BaseInfo :baseInfo="$store.state.user.baseInfo" />
        <NavBar
                title="关注设置"
                left-arrow
                @click-left="$router.go(-1)"
        />
        <Field >
            <template #input>
                   <Checkbox class="line" shape="square" v-model="results.tjzb_01" name="tjzb_01" >
                        体重
                    </Checkbox>
            </template>
        </Field>
        <Field >
            <template #input>
                   <Checkbox class="line" shape="square" v-model="results.tjzb_02" name="tjzb_02" >
                       血压
                    </Checkbox>
            </template>
        </Field>
        <Field >
            <template #input>
                   <Checkbox class="line" shape="square" v-model="results.tjzb_03" name="tjzb_03" >
                       血糖
                    </Checkbox>
            </template>
        </Field>
        <Field >
            <template #input>
                   <Checkbox class="line" shape="square" v-model="results.tjzb_04" name="tjzb_04" >
                       总胆固醇
                    </Checkbox>
            </template>
        </Field>
        <Field >
            <template #input>
                   <Checkbox class="line" shape="square" v-model="results.tjzb_05" name="tjzb_05" >
                       甘油三酯
                    </Checkbox>
            </template>
        </Field>
        <Field >
            <template #input>
                   <Checkbox class="line" shape="square" v-model="results.tjzb_06" name="tjzb_06" >
                       胆固醇酯
                    </Checkbox>
            </template>
        </Field>
        <Field >
            <template #input>
                   <Checkbox class="line" shape="square" v-model="results.tjzb_07" name="tjzb_07" >
                       高密度脂蛋白
                    </Checkbox>
            </template>
        </Field>
        <Field >
            <template #input>
                   <Checkbox class="line" shape="square" v-model="results.tjzb_08" name="tjzb_08" >
                       低密度脂蛋白
                    </Checkbox>
            </template>
        </Field>
        <Field >
            <template #input>
                   <Checkbox class="line" shape="square" v-model="results.tjzb_09" name="tjzb_09" >
                       血尿酸
                    </Checkbox>
            </template>
        </Field>
        <Field >
            <template #input>
                   <Checkbox class="line" shape="square" v-model="results.tjzb_10" name="tjzb_10" >
                       血红蛋白
                    </Checkbox>
            </template>
        </Field>
        <Field >
            <template #input>
                   <Checkbox class="line" shape="square" v-model="results.tjzb_11" name="tjzb_11" >
                       白蛋白
                    </Checkbox>
            </template>
        </Field>

        <br>
        <Button round block type="info" @click="addAttention">保存</Button>

        <Loading type="spinner" color="#1989fa" size="24px" vertical v-if="user_empty" style="margin-top: 40%">获取授权中...</Loading>

        <RLFooter/>
    </div>
</template>

<script>

    import {Checkbox,Field ,Loading,Button } from 'vant';
    import {getTjzbAttention,saveTjzbAttention} from '@/api/tjzb';
    // import {getBaseInfo} from '@/api/patient/baseinfo';
    // import {setToken,removeToken} from '@/utils/auth';
    import {getUser} from '@/api/user';

    export default {
        name: 'QueIdx',
        components: {
            Checkbox,Field,Loading,Button
        },
        data(){
            return {
                active:0,
                followcrfs:[],
                nodata:true,
                user:{
                    userName:'',
                    nickName:'',
                    idCard:'',
                    phone:'',
                    gender:'1',
                    birthday:'',
                    id:'',
                    pid:''
                },
                user_empty:true,
                follow_time:null,
                is_diary:true,
                typeHash:{},
                crfsHash:{},
                activeName:'-1',
                results2:[],
                results:{
                    tjzb_01:null,
                    tjzb_02:null,
                    tjzb_03:null,
                    tjzb_04:null,
                    tjzb_05:null,
                    tjzb_06:null,
                    tjzb_07:null,
                    tjzb_08:null,
                    tjzb_09:null,
                    tjzb_10:null,
                    tjzb_11:null,
                }

            };
        },
        computed:{

        },
        methods:{
            showCrf(idx){
                // this.$router.push({path: '/followcrf/'+idx.id});
                this.$router.push({path: '/crf/',query:{crfname:idx.crfname,sendid:null,itemdataorderid:idx.id,follow_time:null}});
            },
            formaterState(fins){
                if(fins==1){
                    return '未完成';
                }else{
                    return '已完成';
                }
            },
            formaterTime(ctime,uptime){
                let res="";
                if(ctime){
                    res+="创建时间："+this.parseTime(ctime);
                }
                if(uptime){
                    res+="    更新时间："+this.parseTime(uptime);
                }
                return res;
            },
            addAttention(){
                console.log(this.results)
                let data =[]
                for(let key in this.results){
                    console.log(key,this.results[key])
                    if(this.results[key] == true){
                        data.push(key)
                    }
                }
                saveTjzbAttention({},{patientid:this.user.id,attentioninfo:data}).then((response) => {
                    if(response.code==200 ){
                        this.$router.push({path:'/tjzb_task'});
                        this.$toast.success('操作成功');
                    }else{
                        this.$router.push({path:'/tjzb_attention'});
                        this.$toast.fail('操作失败请稍候重试');
                    }
                });
            },

            initData(){
                // let query = this.$route.query;
                // let check_attention=query.check_attention;
                getTjzbAttention({patientid:this.user.id}).then((response) => {
                    console.log(response)
                    if(response.code==200 ){
                        // this.loading=true;
                        this.results = response.data
                        // let allfalse = 0


                    }
                });
                // let follow_time=this.parseTime(new Date(),'{y}-{m}-{d}');

            },
        }
        ,
        created() {
            //生产环境
            // var query = this.$route.query;
            // if(query.code || this.getQueryString('code')){
            //     // console.log("code")
            //     // console.log(query.code)
            //     // console.log(this.getQueryString('code'))
            //     //this.$store.commit('updateOpenid','123456');
            //     this.$store.commit('updateCode',query.code?query.code : this.getQueryString('code'));
            //     setToken(query.code?query.code : this.getQueryString('code'));
            //     getUser().then((res) => {
            //         console.info(res.data);
            //
            //         if(res.data){
            //             this.$store.commit('updateOpenid',res.data.openid);
            //             this.$store.commit('updatePid',res.data.id);
            //             this.$store.commit('updateBaseInfo',res.data);
            //             if(res.data.name !=''){
            //                 this.substatus = true
            //             }
            //             this.user.id=res.data.id;
            //             this.user.name=res.data.name;
            //             this.user.birthday=res.data.birthday;
            //             // this.user.idCard=response.data.idCard;
            //             this.user.telephone=res.data.telephone;
            //             this.user.gender=res.data.gender+'';
            //             this.user.address=res.data.address;
            //             this.user.detail_address=res.data.detail_address;
            //             // this.initBaseInfo();
            //             this.user_empty = false
            //             this.initData()
            //
            //         }
            //     });
            //     //getBaseInfo({}).then((response) => {
            //     //   console.info(response);
            //     // });
            // }else{
            //     removeToken();
            // }
            // 开发环境
            getUser().then((res) => {
                console.info(res.data);

                if(res.data){
                    this.$store.commit('updateOpenid',res.data.openid);
                    this.$store.commit('updatePid',res.data.id);
                    this.$store.commit('updateBaseInfo',res.data);
                    if(res.data.name !=''){
                        this.substatus = true
                    }
                    this.user.id=res.data.id;
                    this.user.name=res.data.name;
                    this.user.birthday=res.data.birthday;
                    // this.user.idCard=response.data.idCard;
                    this.user.telephone=res.data.telephone;
                    this.user.gender=res.data.gender+'';
                    this.user.address=res.data.address;
                    this.user.detail_address=res.data.detail_address;
                    // this.initBaseInfo();
                    this.user_empty = false

                    this.initData();

                }
            });
            // this.initBaseInfo();
            /*初始化列表*/
            // this.initidx();

        },
    }
</script>

<style scoped>

    .is_completed{
        color: #1bde1a;
        /*color: aqua;*/
    }
    .is_uncompleted{
        color:red;
    }

</style>
